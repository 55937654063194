import React, { useState } from 'react'
import { Link } from 'wouter'

import apiV1 from '../../api'
import { orderArticles, eagerlyLazyLoadImages } from '../../utils'
import HeroBanner from '../HeroBanner'
import { OurApproach } from '../Steps'
import LetsMeet from '../LetsMeet'
import Spinner from '../Spinner'


function ServiceSection({ content, specialties, alignImage }) {
    const [index, setIndex] = useState(0)
    const allImages = specialties.map(specialty => specialty?.image?.small_image_portrait?.src)

    return (
        <div className={`service align-image-${alignImage}`}>
            <div className="large-screen-container">
                <div className="service-image">
                    {specialties[index]?.image?.small_image_portrait && <img fetchpriority="high" {...specialties[index].image.small_image_portrait} className="fit-image" style={{ height: '100%', width: '100%' }} />}
                </div>

                <div className="service-main-content">
                    <section className="section-container">
                        <h2 className="h3">{ content.title }</h2>
                        <p className="h5">{ content.subtitle }</p>

                        <div className="specialties">
                            {specialties.map((specialty, i) => (
                                <button
                                    onClick={_ => setIndex(i)}
                                    className={`specialty-option unstyled-button ${index === i && "is-selected"}`}
                                    key={specialty.title}
                                >
                                    <h3 className="h6">{ specialty.title }</h3>
                                    {<p>{ index === i && specialty.text }</p>}
                                </button>
                            ))}
                        </div>

                        <Link to={content.link}>
                            <a className="button" aria-label={content.title}>Learn more</a>
                        </Link>
                    </section>
                </div>
            </div>

            {eagerlyLazyLoadImages(allImages)}
        </div>
    )
}



function FeaturedWork() {
    const allArticles = apiV1.useApi(null, {
        type: 'base.CaseStudyPage',
        fields: 'slug,title,summary,summary_image_small',
        limit: 2,
        hidden: false
    })

    if (allArticles === null) {
        return <Spinner />
    } else if (!allArticles.length || allArticles === null) {
        return null
    }

    return (
        <div className="services-case-studies">
            <section className="section-container-padding">
                <h2 className="h5">Featured Work</h2>

                <div className="two-column-grid">
                    {orderArticles(allArticles).map(article => (
                        <Link to={`/our-work/${article.meta.slug}/`} key={article.title}>
                            <a className="case-study" aria-label={article.title}>
                                <div>
                                    <img loading="lazy" {...article.summary_image_small} src={article.summary_image_small?.url} />
                                    <h3 className="h3" style={{ marginTop: '.5rem' }}>{ article.title }</h3>
                                    {article.summary && <h4 className="h5" style={{ fontWeight: 'regular'}}>{ article.summary }</h4>}
                                </div>
                            </a>
                        </Link>
                    ))}
                </div>

                <br />

                <div className="button-wrapper">
                    <Link to="/our-work/">
                        <a className="button large black">All our work</a>
                    </Link>
                </div>
            </section>
        </div>
    )
}


export default function ServicesPage() {
    const pageData = apiV1.useApi(null, {
        type: 'base.ServicesListPage',
        fields: 'creative_specialties,web_specialties,campaign_and_marketing_specialties',
    })

    const creativeServices = {
        title: 'Brand & Design',
        subtitle: `Brand is more than just a logo. We design brand solutions that are not just visually eye-catching, but are built with purpose and business sensibility.`,
        link: '/services/brand-design/',
    }

    const digitalServices = {
        title: 'Website Design & Development',
        subtitle: `Our full-stack web team builds high-performance websites of all shapes and sizes, with a focus on balancing beautiful, responsive design with fast, accessible function.`,
        link: '/services/web-design-development/',
    }

    const marketingServices = {
        title: 'Marketing & Campaigns',
        subtitle: `From one-off campaigns to sustained long-term strategies, we're a team of marketing masters that specialise in getting brands noticed both online and off.`,
        link: '/services/campaigns-marketing/',
    }

    return (
        <div className="services-page">
            <HeroBanner
                title="Make your message obvious"
                subtitle="As a full-service agency, we're all about creative and empowering solutions that attract attention and communicate convincingly."
            />

            {(pageData && pageData.length) ? <>
                    <ServiceSection
                    content={creativeServices}
                    specialties={pageData[0].creative_specialties[0].value}
                    alignImage="left"
                    />

                    <ServiceSection
                        content={digitalServices}
                        specialties={pageData[0].web_specialties[0].value}
                        alignImage="right"
                    />

                    <ServiceSection
                        content={marketingServices}
                        specialties={pageData[0].campaign_and_marketing_specialties[0].value}
                        alignImage="left"
                    />
            </> : (
                <Spinner />
            )}

            <div className="large-screen-container section-container-x-padding">
                <OurApproach title="Our approach begins with you" />
            </div>

            <LetsMeet title="Looking to level up your brand?" buttonText="Let's chat" buttonURL="/contact" />

            <div className="large-screen-container">
                <FeaturedWork />
            </div>
        </div>
    )
}