import React from 'react'


export default function DiscoverySessionCTA({ children }) {
    return (
        <div className="discovery-session-cta">
            <div className="section-container-padding large-screen-container">
                <div className="grid">
                    <div>
                        { children }
                    </div>
                    <div className="content">
                        <div>
                            <h2 className="h3">Free Discovery Session</h2>
                            <p>Keen for a kōrero? Join us for an obligation-free, no-cost discovery meeting. Let's grab a coffee and unpack awesome.</p>
                            <a href="https://calendly.com/obvs/discovery" target="_blank" rel="noopener" className="button primary large">Book a chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}