// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_throttle with added debounce option
function throttle(func, timeFrame, debounceTime = 0) {
    var lastTime = 0;
    return function (...args) {
        var now = new Date();
        if (now - lastTime >= timeFrame) {
            func(...args);
            lastTime = now;
            // added for when something happens a moment after (eg still scrolling)
            if (debounceTime > 0) {
                setTimeout(() => {
                    handleHeroOnScroll()
                }, debounceTime)
            }
        }
    };
}


const handleHeroOnScroll = event => {
    const heroBanner = document.querySelector('.hero-banner')
    if (heroBanner) {
        heroBanner.style.transition = '1s'
    }
    if (heroBanner && window.pageYOffset > 10) {
        heroBanner.style.padding = 0
        document.removeEventListener('scroll', handleHeroOnScroll)
    }
}

export default initInteractions = function() {
    document.addEventListener(
        'scroll', throttle(handleHeroOnScroll, 100, 100)
    )
}