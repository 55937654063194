import React, { useState } from 'react'

import { eagerlyLazyLoadImages } from '../utils'


function useCarouselState(items) {
    const [index, setIndex] = useState(1)
    items = [null, ...items]

    const nextItem = () => index === items.length - 1 ? setIndex(1) : setIndex(index + 1)
    const prevItem = () => index === 1 ? setIndex(items.length - 1) : setIndex(index - 1)

    const currentItem = items[index]

    return { currentItem, index, nextItem, prevItem, setIndex, isFirst: index === 0, isLast: index === items.length }
}

export default function CarouselWrapper({ children, items, lazyLoadUrls }) {
    const { currentItem, nextItem, prevItem, index } = useCarouselState(items)

    const Buttons = () => (
        <div className="carousel-buttons button-wrapper">
            <button onClick={prevItem} className="unstyled-button" style={{ marginRight: 16 }} aria-label="View previous">
                <img src="/static/images/left-arrow-button-black.svg" width="40" height="41" alt="Left arrow" />
                <img src="/static/images/left-arrow-button-orange.svg" width="40" height="41" alt="Left arrow" />
            </button>
            <button onClick={nextItem} className="unstyled-button" aria-label="View next">
                <img src="/static/images/left-arrow-button-black.svg" width="40" height="41" style={{ transform: 'scaleX(-1)' }} alt="Right arrow" />
                <img src="/static/images/left-arrow-button-orange.svg" width="40" height="41" style={{ transform: 'scaleX(-1)' }} alt="Right arrow" />
            </button>
        </div>
    )

    return (
        <div className="carousel-wrapper">
            {children({ currentItem, buttons: Buttons, key: (Math.random() + 1).toString(36).substring(2) })}
            {eagerlyLazyLoadImages(lazyLoadUrls)}
        </div>
    )
}
