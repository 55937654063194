import React, { useState, useEffect } from 'react'
import { Link } from 'wouter'

import apiV1 from '../../api'
import { orderArticles } from '../../utils'
import Spinner from '../../components/Spinner'
import HeroBanner from '../HeroBanner'
import ErrorMessage from '../ErrorMessage'


const FILTER_TAGS = [
    'Brand',
    'Website',
    'Marketing',
    'Illustration',
    'Photography',
    'Campaign',
    'Videography',
]

const removeHash = () => {
    history.pushState("", document.title, window.location.pathname + window.location.search)
}

function filterArticles(articles, filter) {
    if (filter === null) {
        return articles
    }

    return articles.filter(article => article.tags && article.tags.includes(filter))
}

const updateFilter = (tag, setCurrentFilter) => {
    if (tag && FILTER_TAGS.includes(tag)) {
        setCurrentFilter(tag)
        window.location.hash = tag
    } else {
        setCurrentFilter(null)
        removeHash()
    }
}

function Filters({ currentFilter, setCurrentFilter }) {
    return (
        <div className="case-study-filters section-container" style={{ marginTop: 0 }}>
            <button
                className={currentFilter === null ? 'active-link' : ''}
                onClick={_ => updateFilter(null, setCurrentFilter)}
            >
                All
            </button>
            {FILTER_TAGS.map(filterTag => (
                <button
                    className={currentFilter === filterTag ? 'active-link' : ''}
                    onClick={_ => updateFilter(filterTag, setCurrentFilter)}
                    key={filterTag}
                >
                    { filterTag }
                </button>
            ))}
        </div>
    )
}

function CaseStudiesList({ articles, slugPrefix }) {
    if (!articles.length) 
    if (!articles.length) return <p className="h6 section-container">No articles found.</p>

    return (
        <div className="article-list case-study-list section-container">
            {orderArticles(articles).map((article, index) => (
                <Link to={`${slugPrefix}${article.meta.slug}/`} key={article.title}>
                    <a className="article-link" aria-label={article.title}>
                        <div className="article-image fit-image">
                            <img loading={index < 2 ? "eager" : "lazy"} {...article.summary_image_small} src={article.summary_image_small?.url} />
                        </div>
                        <div className="case-study-text">
                            <h2 className="h3" style={{ marginTop: '.5rem' }}>{ article.title }</h2>
                            <p className="h5" style={{ marginBottom: '0.75rem' }}>{ article.summary }</p>
                        </div>
                        <div className="read-more-button button primary">Read more</div>
                    </a>
                </Link>
            ))}
        </div>
    )
}

export default function CaseStudiesListPage() {
    const slugPrefix = '/our-work/'

    const defaultFilter = window.location.hash ? window.location.hash.replace('#', '') : null
    const [currentFilter, setCurrentFilter] = useState(null)

    useEffect(() => {
        updateFilter(defaultFilter, setCurrentFilter)
    })

    const allArticles = apiV1.useApi(slugPrefix, {
        type: 'base.CaseStudyPage',
        fields: 'tags,weight,title,summary,summary_image_small',
        limit: 50,
        hidden: false
    })

    return (
        <div>
            <ErrorMessage showIf={allArticles === false} />

            <HeroBanner
                title="Our mahi"
                subtitle="Celebrating some of our favourite collabs."
            />

            <div className="large-screen-container">
                <Filters currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />

                {allArticles ? <CaseStudiesList slugPrefix={slugPrefix} articles={filterArticles(allArticles, currentFilter)} /> : <Spinner />}
            </div>
        </div>
    )
}
