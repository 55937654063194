import React, { useState } from 'react'
import { Link } from 'wouter'

import apiV1 from '../../api'
import partners from '../../data/partners'
import quotes from '../../data/quotes'
import { formatDate, orderArticles, useWindowSize } from '../../utils'
import HeroBanner from '../HeroBanner'
import LetsMeet from '../LetsMeet'
import Spinner from '../Spinner'
import CarouselWrapper from './../CarouselWrapper'
import QuoteSection from '../QuoteSection'
import DiscoverySessionCTA from '../DiscoverySessionCTA'


function CaseStudiesSection() {
    const articles = apiV1.useApi(null, {
        type: 'base.CaseStudyPage',
        fields: 'title,summary,summary_image_small,slug,tags',
        limit: 10,
        order: 'weight',
        hidden: false
    })

    if (articles === null) {
        return <Spinner />
    } else if (!articles.length || articles === false) {
        return null
    }

    const carouselImages = articles.map(caseStudy => caseStudy.summary_image_small?.url)

    return <>
        <CarouselWrapper items={articles} lazyLoadUrls={carouselImages}>
            { ({ currentItem, buttons, key }) => {
                const caseStudy = currentItem
                const image = caseStudy.summary_image_small
                const Buttons = buttons || null

                return <div key={key}>
                    <div className="two-column-grid">
                        <div>
                            <Link to={`our-work/${caseStudy.meta.slug}/`}>
                                <a aria-label={`${caseStudy.title} case study`} className="case-study-cursor">
                                    <div>
                                        {image && <img fetchpriority="high" className="summary-image" {...image} src={image.url} />}
                                    </div>
                                </a>
                            </Link>

                            <div className="mobile-only">
                                <Buttons />
                            </div>
                        </div>

                        <div className="flex-space-vertically">
                            <div>
                                <Link to={`our-work/${caseStudy.meta.slug}/`}>
                                    <a aria-label={`${caseStudy.title} case study`} className="case-study-cursor">
                                        <strong className="h6">Case study</strong>
                                        <h3 className="h3">{ caseStudy.title }</h3>
                                        <p className="h5" _style={{ minHeight: '81px' }}>{ caseStudy.summary }</p>
                                    </a>
                                </Link>

                                <div className="case-study-tags">
                                    {articles[0].tags.map((tag, i) => <span key={tag}>
                                        {i !== 0 && <span>&nbsp;/&nbsp;</span>}
                                        <Link to={`our-work#${tag}`}><a>{ tag }</a></Link>
                                    </span>)}
                                </div>
                            </div>
                            <div>
                                <div className="flex-wrap">
                                    <Link to={`our-work/${caseStudy.meta.slug}/`}>
                                        <a className="button black">View case study</a>
                                    </Link>
                                    <Link to="/our-work/">
                                        <a className="button black">All our work</a>
                                    </Link>
                                </div>

                                <div className="tablet-plus-only">
                                    <Buttons />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }}
        </CarouselWrapper>
    </>
}

const renderSpecialties = (data, property) => {
    if (!data || !data.length) {
        return null
    }

    return <ul className="unstyled-list">
        {data[0][property][0].value.map(specialty => (
            <li key={specialty.title}>{ specialty.title }</li>
        ))}
    </ul>
}

function ServicesSection() {
    const windowSize = useWindowSize()
    const isMobile = windowSize && windowSize.width < 768
    const isDesktop = windowSize && windowSize.width >= 768

    const data = apiV1.useApi(null, {
        type: 'base.ServicesListPage',
        fields: 'creative_specialties,web_specialties,campaign_and_marketing_specialties',
    })

    return (
        <div className="services-section">
            <section className="large-screen-container section-container-padding">
                <h2 className="h2 article-width-long">Creative & Digital Solutions that Make it Obvious</h2>
                <h3 className="h6 article-width">
                    Our work lives up to our name. If you have a message, product, business or brand you want to share with the world, let's work together to make it obvious.
                </h3>

                <div className="services-list">
                    {/* todo: link should be a variable used with services page (globals.js variable file maybe?) */}
                    <Link to="/services/brand-design/">
                        <a style={{ backgroundImage: 'static/images/service_cards/creative.png' }}>
                            {isMobile && <img src="static/images/service_cards/mobile_landscape/creative.png" style={{ width: '100%' }} alt="Design illustration graphic of an elephant and a pencil" />}
                            <div className="service-card-tint" style={{ padding: '2rem', backgroundImage: isDesktop && `url('static/images/service_cards/creative.jpg')` }}>
                                <h3 className="h3">Brand & Design</h3>
                                <div>
                                    { renderSpecialties(data, 'creative_specialties') }
                                    <div className="button">Learn more</div>
                                </div>
                            </div>
                        </a>
                    </Link>
                    <Link to="/services/web-design-development/">
                        <a>
                            {isMobile && <img src="static/images/service_cards/mobile_landscape/digital.jpg" style={{ width: '100%' }} alt="A responsive website design shown on mobile, tablet and desktop screens" />}
                            <div className="service-card-tint" style={{ padding: '2rem', backgroundImage: isDesktop && `url('static/images/service_cards/digital.jpg')` }}>
                                <h3 className="h3">Website Design & Development</h3>
                                <div>
                                    { renderSpecialties(data, 'web_specialties') }
                                    <div className="button">Learn more</div>
                                </div>
                            </div>
                        </a>
                    </Link>
                    <Link to="/services/campaigns-marketing/">
                        <a>
                            {isMobile && <img src="static/images/service_cards/mobile_landscape/campaign_and_marketing.jpg" style={{ width: '100%' }} alt="A design showing reusable coffee mugs and old computer technology against a green background" />}
                            <div className="service-card-tint" style={{ padding: '2rem', backgroundImage: isDesktop && `url('static/images/service_cards/campaign_and_marketing.jpg')` }}>
                                <h3 className="h3">Campaign & Marketing</h3>
                                <div>
                                    { renderSpecialties(data, 'campaign_and_marketing_specialties') }
                                    <div className="button">Learn more</div>
                                </div>
                            </div>
                        </a>
                    </Link>
                </div>

                <Link to="/services/">
                    <a className="all-services-button button large">All services</a>
                </Link>
            </section>
        </div>
    )
}

function PartnersSection() {
    const [isExpanded, setIsExpanded] = useState(false)
    const windowSize = useWindowSize()
    const isMobile = windowSize.width < 768
    const showAll = !isMobile || isExpanded
    const visiblePartners = showAll ? partners : partners.slice(0, 8)

    return (
        <div className="home-page-partners-section">
            <section className="large-screen-container section-container-padding">
                <h2>We are proud to be creative & digital partners of these amazing Kiwi businesses.</h2>

                <div className="partners-list">
                    {visiblePartners.map(({image, href}, index) => (
                        <a key={href + index} href={href} target="_blank" rel="noopener" className="partner-icon fit-image" style={{ border: '#3C3636 solid 1px', display: 'flex', justifyContent: 'center' }}>
                            {/* black and white by default, color on hover */}
                            <div className="img">
                                <img loading="lazy" decoding="async" src={`${image.path}/white.svg`} width="192" height="124" alt={image.alt} />
                            </div>
                            <div className="img" style={{ background: image.background }}>
                                <img fetchpriority="low" src={`${image.path}/colour.svg`} width="192" height="124" alt={image.alt} />
                            </div>
                        </a>
                    ))}
                </div>

                {isMobile && (
                    <button onClick={() => setIsExpanded(!isExpanded)} className="unstyled-button button" style={{ display: 'block', margin: '2rem auto 0' }}>
                        {showAll ? 'Show less' : 'Show more'}
                    </button>
                )}
            </section>
        </div>
    )
}

function BlogSection() {
    const allArticles = apiV1.useApi(null, {
        type: 'base.BlogPage',
        fields: 'first_published_at,title,summary,summary_image_small',
        order: '-first_published_at',
        limit: 4,
        hidden: false
    })

    if (!allArticles) {
        return null
    }

    return (
        <div className="home-page-blog-section">
            <section className="large-screen-container section-container-padding">
                <h2 className="h4">Brand and marketing insights to inspire agency of your own.</h2>

                <div className="blog-list four-column-grid">
                    {orderArticles(allArticles).map((article, index) => (
                        <Link to={`/blog/${article.meta.slug}/`} key={article.title + index}>
                            <a className="blog-post" aria-label={article.title}>
                                <div>
                                    <img {...article.summary_image_small} src={article.summary_image_small?.url} />
                                    <h3 className="h6">{ article.title }</h3>
                                    <p className="h7" style={{ fontSize: 14 }}>{ formatDate(article.meta.first_published_at) }</p>
                                </div>
                            </a>
                        </Link>
                    ))}
                </div>

                <br />

                <div className="button-wrapper">
                    <Link to="/blog/">
                        <a className="button large black">More blog posts</a>
                    </Link>
                </div>
            </section>
        </div>
    )
}


export default function HomePage() {
    const quoteImages = quotes.map(quote => quote?.image?.src)

    const MobileIllustration = () => (
        <img src="/static/images/illustrations/outstanding-sheep-v2.svg" fetchpriority="high" className="sheep-illustration section-container" width="153" height="91" style={{ marginBottom: 0, position: 'relative', bottom: 15, width: '60%' }} alt="" />
    )
    const BackgroundImages = () => <>
        <img src="/static/images/illustrations/average-sheep-v2.svg" fetchpriority="high" className="section-container" width="153" height="91" style={{ position: 'absolute', top: '10%', left: '10%', }} alt="" />
        <img src="/static/images/illustrations/outstanding-sheep-v2.svg" fetchpriority="high" className="section-container" width="153" height="91" style={{ position: 'absolute', bottom: '-5%', right: '4%', width: 254, height: 322 }} alt="" />
    </>

    return (
        <div className="home-page">
            <HeroBanner
                title="STAND OUT FOR WHAT YOU STAND FOR"
                subtitle="We're the Wellington-based creative agency building brands with purpose."
                // // quick fix to remove this. remove fully once we have the new illustration
                // mobileIllustration={MobileIllustration}
                // backgroundImages={BackgroundImages}
            >
                <div className="flex-wrap" style={{ justifyContent: 'center', gap: '1rem' }}>
                    <a href="https://calendly.com/obvs/discovery" target="_blank" rel="noopener" className="button large">Free consultation</a>
                    <Link to="/services/">
                        <a className="button large">Our services</a>
                    </Link>
                </div>
            </HeroBanner>

            <div className="bg-white">
                <div className="large-screen-container">
                    <div className="case-studies-section section-container-padding">
                        <CaseStudiesSection />
                    </div>
                </div>

                <ServicesSection />

                <div className="large-screen-container section-container-padding">
                    <CarouselWrapper items={quotes} lazyLoadUrls={quoteImages}>
                        {({ currentItem, buttons, key }) => <QuoteSection quote={currentItem} buttons={buttons} key={key} />}
                    </CarouselWrapper>
                </div>

                <PartnersSection />

                <LetsMeet title="Meet our creative minds" buttonText="Our team" buttonURL="/about" />

                <BlogSection />

                <DiscoverySessionCTA>
                    <img src="/static/images/illustrations/Astronaut.svg" width="346" height="290" alt="" />
                </DiscoverySessionCTA>
            </div>
        </div>
    )
}