import React, { useState, useEffect } from 'react'

const apiClearDate = document.getElementById('cache_clear_date')?.innerHTML
const caseStudyCacheClearData = document.getElementById('case_study_cache_clear_date')?.innerHTML
const blogCacheClearDate = document.getElementById('blog_cache_clear_date')?.innerHTML

// main url parameters are:
// - slug (eg 'our-work')
// - type (eg 'base.CaseStudy'. required to select fields)
// - fields (comma separated list of fields to include. use '*' to include all and -field to remove a field)
// - limit (max number of results)
// - order (field to order things by)
// - search
function getUrlParams(params) {
    if (!params) {
        return ''
    }

    return '?' + new URLSearchParams(params).toString() 
}

const getCacheInvalidationString = pageType => {
    if (pageType === 'base.CaseStudyPage') {
        return caseStudyCacheClearData
    }

    if (pageType === 'base.BlogPage') {
        return blogCacheClearDate
    }

    return apiClearDate
}

async function setPageData(state, setState, params = null) {
    if (state !== null) return

    const cacheInvalidationString = getCacheInvalidationString(params?.type)
    const urlParams = getUrlParams({ _: cacheInvalidationString, ...params }) // pass hidden date from admin cache setting to invalidate cache

    try {
        const response = await fetch(`/api/v2/pages/${urlParams}`)
        const parsedResponse = await response.json()
        // console.log(`/api/v2/pages/${urlParams}`, parsedResponse) // note: uncomment to debug
        setState(parsedResponse.items)
    } catch (error) {
        console.error('Failed to set page data:', `/api/v2/pages/${urlParams}`, error)
        setState(false)
    }
}

function useApi(slug, params) {
    const [data, setData] = useState(null)
    setPageData(data, setData, params)

    // reset state when url/params change. Pass null if not used
    useEffect(() => {
        setData(null)
    }, [slug])

    return data
}

async function updateDocumentTitle(slug) {
    const data = useApi(slug, {
        slug: slug,
        fields: '_,title,search_description',
        limit: 1,
        _: Math.random().toString().replace('.','') // always get latest version so we don't have to think about edge cases
    })

    if (data?.length === 1) {
        // update title tag
        const pageData = data[0]
        const documentTitle = pageData.meta?.seo_title || data[0].title
        document.title = `${documentTitle} | Obvious Brand Partners, Wellington NZ`

        // update meta description tag
        const search_description = pageData.meta?.search_description || `Need a new brand or website for your business? We're a marketing, design & digital agency in NZ's creative capital, Wellington.  For branding, web and marketing campaign solutions - Make it Obvious.`
        document.querySelector('meta[name="description"]').setAttribute("content", search_description)
        document.querySelector('meta[property="og:title"]') && document.querySelector('meta[property="og:title"]').setAttribute("content", '') // TODO: this is a temp fix to prevent sharing with incorrect data

        return '200'
    } else if (data) {
        console.warn('Could not update document title')
        return '404' // return false to say that it couldn't be updated
    }
}

export default apiV1 = {
    useApi,
    updateDocumentTitle,
}