import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import { Link, Route, Switch, useLocation } from 'wouter'

import apiV1 from './api'
import initInteractions from './interactions'
import Nav from './components/Nav'
import HomePage from './components/pages/HomePage'
import CaseStudiesListPage from './components/pages/CaseStudiesListPage'
import CaseStudyPage from './components/pages/CaseStudyPage'
import StandardPage from './components/pages/StandardPage'
import BlogListPage from './components/pages/BlogListPage'
import BlogPage from './components/pages/BlogPage'
import ServicesPage from './components/pages/ServicesPage'
import ServicePage from './components/pages/ServicePage'
import AboutPage from './components/pages/AboutPage'
import ContactPage from './components/pages/ContactPage'

// import * as Sentry from '@sentry/react'

// If taking advantage of automatic instrumentation (highly recommended)
// import { BrowserTracing } from '@sentry/tracing' // todo: this is quite large at 55kb/17kb gzipped
// Or, if only manually tracing
// import * as _ from "@sentry/tracing"
// Note: You MUST import the package in some way for tracing to work

// // TODO: logging with Sentry is disabled in the front end as it adds around 100k even with gzip. Add it back on an as-needed basis.
// // This is also only partly integrated and doesn't tract everything as the original Preact/Wouter setup wasn't fully supported. But we
// // can look at adding better support now we're using React (might still need to switch to react-router though)
// Sentry.init({
//     dsn: 'https://6d323d7e4a7944d180db97945f3f62cb@o4504556142985216.ingest.sentry.io/4504556142985216',

//     // This enables automatic instrumentation (highly recommended), but is not
//     // necessary for purely manual usage
//     // react router v7+ is also supported, but since we're using Wouter instead I'll need to research if it will work
//     integrations: [new BrowserTracing()],

//     tracesSampleRate: 1,

//     beforeSend: (event) => {
//         if (window.location.hostname === 'localhost') {
//             return null;
//         }
//         return event;
//     }
// })


const onscrollButtonEvent = (event) => {
    const goToTopButton = document.querySelector('.go-to-top-button')
    if (window.scrollY > 250 && goToTopButton) {
        goToTopButton.style.display = 'block'
        window.removeEventListener('scroll', onscrollButtonEvent)
    }
}
window.addEventListener('scroll', onscrollButtonEvent)

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        console.error('ErrorBoundary:', error)
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return (
                <section className="section-container large-screen-container">
                    <h1 className="h2">Something went wrong</h1>
                    <br />
                    <div className="flex-wrap">
                        <button onClick={() => location.reload()} className="unstyled-button button primary">Reload</button>
                        <a href="/" className="button orange">Return to homepage</a>
                    </div>
                </section>
            )
        }

        return this.props.children
    }
}

function ViewWrapper({ bodyClass, slug, children }) {
    useEffect(() => {
        document.querySelector('html').className = bodyClass
    }, [bodyClass])

    if (slug) {
        const pageStatusPromise = apiV1.updateDocumentTitle(slug) // note side effect: both updates title and returns 200/404 status

        const skipButton = document.getElementById('skip-to-main-content-button') // move focus to top as if reloaded
        if (skipButton) {
            skipButton.focus()
            skipButton.blur()
        }

        pageStatusPromise?.then && pageStatusPromise.then(pageStatus => {
            if (pageStatus === '404') {
                console.error(slug, pageStatus, 'Page not found')
                window.location.replace('/404')
            }
        }).catch(error => console.error('Failed to get page status', error))
    }

    return children
}

function App() {
    const [location, setLocation] = useLocation()
    const firstUpdate = useRef(true)
    useEffect(() => {
        // scroll to top when location changes, unless the person just scrolled to a section or added a filter
        !location.includes('#') && window.scrollTo(0, 0)

        // if not first render update class to reflect that the admin bar is out of date
        // https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
        if (firstUpdate.current) {
            firstUpdate.current = false
        } else {
            document.querySelector('.admin-bar').classList.remove ('is-correct-page')
        }

        // hide scroll to top
        const goToTopButton = document.querySelector('.go-to-top-button')
        if (goToTopButton) goToTopButton.style.display = 'none'
        window.addEventListener('scroll', onscrollButtonEvent)
    }, [location])

    return (
        <div>
            <Nav />

            <main id="main-content">
                <Switch>
                    <Route path="/">
                        <ViewWrapper bodyClass="home-page-theme" slug="homepage">
                            <HomePage />
                        </ViewWrapper>
                    </Route>

                    <Route path="/about">
                        <ViewWrapper bodyClass="about-page-theme" slug="about">
                            <AboutPage slug="/about" />
                        </ViewWrapper>
                    </Route>

                    <Route path="/services">
                        <ViewWrapper bodyClass="services-page-theme" slug="services">
                            <ServicesPage slug="services"/>
                        </ViewWrapper>
                    </Route>

                    <Route exact path="/services/:slug">
                        {props => <div>
                            <ViewWrapper bodyClass="service-page-theme" slug={props.slug}>
                                <ServicePage slug={props.slug} />
                            </ViewWrapper>
                        </div>}
                    </Route>

                    <Route path="/our-initiatives">
                        <ViewWrapper bodyClass="standard-page-theme" slug="our-initiatives">
                            <StandardPage slug="our-initiatives"/>
                        </ViewWrapper>
                    </Route>

                    <Route path="/blog">
                        <div>
                            <ViewWrapper bodyClass="blog-list-page-theme" slug="blog">
                                <BlogListPage slug="blog"/>
                            </ViewWrapper>
                        </div>
                    </Route>

                    <Route exact path="/blog/:slug">
                        {props => <div>
                            <ViewWrapper bodyClass="blog-article-page-theme" slug={props.slug}>
                                <BlogPage slug={props.slug} />
                            </ViewWrapper>
                        </div>}
                    </Route>

                    <Route path="/our-work">
                        <div>
                            <ViewWrapper bodyClass="case-studies-list-page-theme" slug="our-work">
                                <CaseStudiesListPage />
                            </ViewWrapper>
                        </div>
                    </Route>

                    <Route exact path="/our-work/:slug">
                        {props => <div>
                            <ViewWrapper bodyClass="case-studies-article-page-theme" slug={props.slug}>
                                <CaseStudyPage slug={props.slug} />
                            </ViewWrapper>
                        </div>}
                    </Route>

                    <Route path="/contact">
                        <div>
                            <ViewWrapper bodyClass="contact-page-theme" slug="contact">
                                <ContactPage />
                            </ViewWrapper>
                        </div>
                    </Route>

                    <Route path="/thanks">
                        <ViewWrapper bodyClass="thanks-or-404-page-theme" slug="thanks">
                            <div className="large-screen-container">
                                <div className="section-container">
                                    <h1 className="h2">Thanks for your message</h1>
                                    <p className="h6">We'll be in touch soon to chat through your project.</p>
                                    <br />
                                    <Link to="/" className="button primary">Return to Home Page</Link>
                                </div>
                            </div>
                        </ViewWrapper>
                    </Route>

                    <Route exact path="/:rest*" status={404} slug={null}>
                        <ViewWrapper bodyClass="thanks-or-404-page-theme">
                            <div className="large-screen-container">
                                <div className="section-container">
                                    <h1 className="h2">404</h1>
                                    <p className="h6">Sorry, we couldn't find that page.</p>
                                    <br />
                                    <Link to="/" className="button primary">Return to Home Page</Link>
                                </div>
                            </div>
                        </ViewWrapper>
                    </Route>
                </Switch>
            </main>
        </div>
    )
}

const root = ReactDOM.createRoot(document.getElementById('app'))
const debug = true // NOTE: switch to true to disable AppWrapper which can hide some error messages
root.render(
    <div>
        {debug ? (
                <App />
            ) : (
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            )
        }
    </div>
)

// show footer only after everything's loaded to prevent jumping
document.addEventListener('DOMContentLoaded', () => {
    document.getElementById('footer').style.display = 'block'

    initInteractions()
}, false)

window._scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }) // behavior: 'smooth'}

    const goToTopButton = document.querySelector('.go-to-top-button')
    if (goToTopButton) goToTopButton.style.display = 'none'

    setTimeout(() => {
        // allow 500ms to scroll to top, then watch for scroll events and reset focus
        window.addEventListener('scroll', onscrollButtonEvent)
        const skipButton = document.getElementById('skip-to-main-content-button') // move focus to top as if reloaded
        if (skipButton) {
            skipButton.focus()
            skipButton.blur()
        }
    }, 500);
}