export default partners = [
    {
        image: {
            alt: "Alzheimer's NZ",
            path: "/static/images/partners-logos/Alzheimers_NZ",
            background: "#E5F2FF"
        },
        href: "https://alzheimers.org.nz/",
    },
    {
        image: {
            alt: "Aro Digital",
            path: "/static/images/partners-logos/Aro_digital",
            background: "#F50052"
        },
        href: "https://aro.digital/",
    },
    {
        image: {
            alt: "Bats Theatre",
            path: "/static/images/partners-logos/Bats",
            background: "#B41F28"
        },
        href: "https://bats.co.nz/",
    },
    {
        image: {
            alt: "Brewtown",
            path: "/static/images/partners-logos/Brewtown",
            background: "#D1A06E"
        },
        href: "https://www.brewtown.co.nz/",
    },
    {
        image: {
            alt: "Curriza",
            path: "/static/images/partners-logos/Curriza",
            background: "#FACF57"
        },
        href: "https://currizza.kiwi/",
    },
    {
        image: {
            alt: "Hutt City Council",
            path: "/static/images/partners-logos/Hutt_City",
            background: "#00A0AF"
        },
        href: "https://www.huttcity.govt.nz/",
    },
    {
        image: {
            alt: "Kepla",
            path: "/static/images/partners-logos/Kepla",
            background: "#020126"
        },
        href: "https://kepla.io/",
    },
    {
        image: {
            alt: "Masterlink",
            path: "/static/images/partners-logos/Masterlink",
            background: "#003A5D"
        },
        href: "https://masterlink.co.nz/",
    },
    {
        image: {
            alt: "Matu",
            path: "/static/images/partners-logos/Matu",
            background: "#00a7a9"
        },
        href: "https://matu.co.nz/",
    },
    {
        image: {
            alt: "QMC",
            path: "/static/images/partners-logos/QMC",
            background: "#92B8DB"
        },
        href: "https://www.qmc.school.nz/",
    },
    {
        image: {
            alt: "St. Pats Silverstream",
            path: "/static/images/partners-logos/St_Pats_Silverstream",
            background: "#79ACD1"
        },
        href: "https://www.stream.school.nz/",
    },
    {
        image: {
            alt: "Study Spy",
            path: "/static/images/partners-logos/Study_Spy",
            background: "#FFFFFF"
        },
        href: "https://studyspy.ac.nz/",
    },
    {
        image: {
            alt: "Suitefiles",
            path: "/static/images/partners-logos/Suitefiles",
            background: "#001333"
        },
        href: "https://www.suitefiles.com/",
    },
    {
        image: {
            alt: "Te Mahi Ako",
            path: "/static/images/partners-logos/Te_Mahi_Ako",
            background: "#8300FF"
        },
        href: "https://www.temahiako.org.nz/",
    },
    {
        image: {
            alt: "TED X Wellington",
            path: "/static/images/partners-logos/Ted_X_Wellington",
            background: "#FFFFFF"
        },
        href: "https://tedxpipitea.com/",
    },
    {
        image: {
            alt: "TEU",
            path: "/static/images/partners-logos/TEU",
            background: "#EF5A22"
        },
        href: "https://teu.ac.nz/",
    },
    {
        image: {
            alt: "Weltec",
            path: "/static/images/partners-logos/Weltec",
            background: "#009589"
        },
        href: "https://www.whitireiaweltec.ac.nz/",
    },
    {
        image: {
            alt: "WMG",
            path: "/static/images/partners-logos/WMG",
            background: "#FFFFFF"
        },
        href: "https://www.wellingtonmedical.co.nz/",
    },
]