import React from 'react'

export default function ErrorMessage({ showIf=true, message='Sorry, it looks like something failed to load.', showReload=true }) {
    if (!showIf) {
        return null // shown by default but supports optional conditional
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', background: 'white', padding: '1rem 0' }}>
            <div>
                {showReload ? (
                    <div className="flex-wrap" style={{ alignItems: 'center' }}>
                        <p className="h6" style={{color: 'black'}}>{message}</p>
                        <button onClick={() => location.reload()} className="unstyled-button button small primary" style={{color: 'black'}}>Reload</button>
                        <a href="/" className="button small orange">Return to homepage</a>
                    </div>
                ) : null}
            </div>
        </div>
    )
}