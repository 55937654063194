import React, { useState } from 'react'
import { Link } from 'wouter'


export const MENU_ITEMS = [
    {
        'label': 'Services',
        'url': '/services/'
    },
    {
        'label': 'Our Work',
        'url': '/our-work/'
    },
    {
        'label': 'Our Initiatives',
        'url': '/our-initiatives/'
    },
    {
        'label': 'About us',
        'url': '/about/'
    },
    {
        'label': 'Blog',
        'url': '/blog/'
    },
]

const Logo = () => (
    <Link to="/">
        <a aria-label="Go to homepage">
            <img src="/static/images/obvious-logo-white.svg" className="logo" width="161" height="28" alt="Obvious logo" />
        </a>
    </Link>
)


function DesktopNav() {
    const RightMenu = () => (
        <div className="nav-right">
            {MENU_ITEMS.map(item =>
                <Link to={item.url} key={item.label}>
                    <a>{ item.label }</a>
                </Link>
            )}
            <Link to="/contact/">
                <a className="button">Contact us</a>
            </Link>
        </div>
    )

    return (
        <nav className="desktop-nav-background">
            <div className="desktop-nav large-screen-container">
                <div className="nav-left">
                    <Logo />
                </div>

                <div />

                <RightMenu />
            </div>
        </nav>
    )
}


function MobileNav() {
    const [isOpen, setIsOpen] = useState(false)
    const ariaLabel = isOpen ? 'Close nav' : 'Open nav'

    return (
        <nav className="mobile-nav" style={isOpen ? { background: 'var(--charcoal)'} : null}>
            <div className="mobile-nav-banner" onClick={() => isOpen && setIsOpen(false)}>
                <Logo />
                <button onClick={() => setIsOpen(!isOpen)} className="unstyled-button" aria-label={ariaLabel}>
                    {isOpen ? (
                        <img className="close-icon" src="/static/images/close.svg" width="34" height="34" alt="Close icon" />
                    ) : (
                        <img className="open-icon" src="/static/images/hamburger.svg" width="23" height="18" alt="Hamburger icon" />
                    )}
                </button>
            </div>
            <div id="mobile-menu" className={`mobile-nav-open-section ${isOpen && 'is-open'}`} onClick={() => setIsOpen(false)}>
                <>{MENU_ITEMS.map(item =>
                    <Link to={item.url} key={item.label}>
                        <a className="h5" key={item.label}>{ item.label }</a>
                    </Link>
                )}</>
                <Link to="/contact/">
                    <a className="h5">Contact us</a>
                </Link>
            </div>
        </nav>
    )
}


export default function Nav() {
    return <>
        <MobileNav />
        <DesktopNav />
    </>
}
