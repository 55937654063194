import React from 'react'
import { Link } from 'wouter'

import { orderArticles } from '../utils'
import { formatDate } from '../utils'
import ErrorMessage from './ErrorMessage'


export const caseStudiesMoreArticlesConfig = {
    columnsClass: 'two-column-grid',
    baseUrl: '/our-work/',
    articleTitleClass: 'h3',
}

export const blogMoreArticlesConfig = {
    mainHeading: 'Check out more of our blog posts',
    columnsClass: 'four-column-grid',
    baseUrl: '/blog/',
    articleTitleClass: 'h5',
    showDate: true,
}

export default function MoreArticles({
    className,
    articles,
    mainHeading,
    columnsClass,
    articleTitleClass,
    baseUrl,
    showDate
}) {
    if (articles === false) {
        return <ErrorMessage />
    }

    const otherArticles = articles
    return (
        <div className="more-articles section-container-padding">
            <section className={`large-screen-container ${className }`}>
                <h3 className="h5">
                    {mainHeading}
                </h3>
                <br />

                <div className={columnsClass}>
                    {otherArticles && orderArticles(otherArticles).map(article => (
                        <Link to={`${baseUrl}${article.meta.slug}/`} key={article.title}>
                            <a>
                                <div>
                                    <img loading="lazy" {...article.summary_image_small} src={article.summary_image_small?.url} />
                                    <h3 className={articleTitleClass} style={{ marginTop: '.25rem' }}>
                                        {article.title}
                                    </h3>
                                    <h4 className="h5">
                                        {article.summary}
                                    </h4>
                                    {showDate && <p className="date">{ formatDate(article.meta.first_published_at) }</p>}
                                </div>
                            </a>
                        </Link>
                    ))}
                </div>
            </section>
        </div>
    )
}