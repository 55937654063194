import React, { useState } from 'react'

import QuoteSection from './QuoteSection'
import LetsMeet from './LetsMeet'
import Steps from './Steps'


function Richtext({ item }) {
    return <div className="richtext article-width section-container-y-half" dangerouslySetInnerHTML={{__html: item.value}}></div>
}

function Image({ item }) {
    const image = item.value.image?.width_1792
    const text = item.value.text

    if (!image) return null

    return (
        <div>
            <img loading="lazy" {...image} className="section-container-y-padding-half" style={{ width: '100%' }} />
            {text && <div dangerouslySetInnerHTML={{ __html: text }} className="h6" style={{ textAlign: 'center', marginTop: '-1.5rem' }} />}
        </div>
    )
}

function ValuesAccordion({ item }) {
    const [openId, setOpenId] = useState(null)
    const values = item.value.value_items

    const AccordionItem = ({value, id}) => {
        const isOpen = openId === id

        return (
            <div className="accordion-item-wrapper">
                <div className="accordion-item">
                    <button onClick={() => setOpenId(isOpen ? null : id)} className="accordion-title h3 unstyled-button" style={{display: 'flex', justifyContent: 'space-between', width: '100%' }} aria-label={isOpen ? 'Close' : 'Open'}>
                        <div className="h3">
                            {value.title}
                        </div>
                        <div>
                            {!isOpen ? (
                                <img src="/static/images/chevron-up.svg" width="22" height="14" style={{ transform: 'rotate(180deg)', filter: 'invert(100%)', height: '100%', transition: '0s' }} alt="Chevron up" />
                            ) : (
                                <img src="/static/images/chevron-up.svg" width="22" height="14" style={{ filter: 'invert(100%)', height: '100%', transition: '0s' }} alt="Chevron down" />
                            )}
                        </div>
                    </button>

                    {isOpen && <div className="accordion-content">
                        <div className="richtext" dangerouslySetInnerHTML={{__html: value.content}}></div>
                    </div>}
                </div>
            </div>
        )
    }

    return (
        <section className="accordion-block">
            {values.map((value, id) => (
                <AccordionItem value={value} id={id} key={id} />
            ))}
        </section>
    )
}

function ValuesGrid({ item }) {
    const values = item.value.value_items

    return (
        <div className="values-grid three-column-grid">
            {values.map(value => (
                <div key={value.title}>
                    <h3 className="h3">{value.title}</h3>
                    <div className="richtext" dangerouslySetInnerHTML={{__html: value.content}} />
                </div>
            ))}
        </div>
    )
}

function ValuesListBlock({ item }) {
    return <>
        <ValuesAccordion item={item} />
        <ValuesGrid item={item} />
    </>
}

function TeamMembers({ item, setSelectedPersonName }) {
    const teamMembers = item.value

    return (
        <div className="team-member-list three-column-grid section-container-y-padding">
            {teamMembers.map(person => (
                <div key={person.name}>
                    <button className="team-member unstyled-button read-more-cursor" onClick={() => setSelectedPersonName(person.name)} aria-label={`View bio for ${person.name}, ${person.job_title}`}>
                        <div>
                            {person?.image?.small_image_portrait && <img loading="lazy" className="fit-image" {...person.image.small_image_portrait} />}
                        </div>
                        <h3 className="h3">{ person.name }</h3>
                        <strong className="h6" style={{ marginTop: '.25rem' }}>{ person.job_title }</strong>
                    </button>
                </div>
            ))}
        </div>
    )
}

function QuoteBlock({ item }) {
    return <QuoteSection quote={{
        ...item.value,
        text: item.value.quote,
        image: item.value.image?.small_image_portrait,
    }} />
}

function GridBlock({ item }) {
    return (
        <div className={`grid-block ${item.value.columns}-column-grid section-container-y-padding-half`}>
            {item.value.items.map((column, index) => (
                column?.content?.length && column.content.map(columnItem => (
                    <div className={`grid-column-span-${column.columns}`} key={columnItem.id}>
                        {columnItem.type === 'text' ? (
                            <div className="richtext article-width" dangerouslySetInnerHTML={{__html: columnItem.value}} />
                        ) : (
                            columnItem?.value?.small_image_uncropped && <img loading="lazy" {...columnItem.value.small_image_uncropped} />
                        )}
                    </div>
                ))
            ))}
        </div>
    )
}

function StepsBlock({ item }) {
    return <Steps
        title={item.value.title}
        step1={<div dangerouslySetInnerHTML={{__html: item.value.step_one}}></div>}
        step2={<div dangerouslySetInnerHTML={{__html: item.value.step_two}}></div>}
        step3={<div dangerouslySetInnerHTML={{__html: item.value.step_three}}></div>}
    />
}

function VideoBlock({ item }) {
    return (
        <div className="video-component section-container-y-half">
            <figure className="image is-16by9">
                <iframe
                    className="has-ratio"
                    src={item.value.url} title={item.value.title}
                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position: 'absolute', top:0, left:0, width: '100%', height: '100%'}}></iframe>
            </figure>

            <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
    )
}

function LetsMeetBock({ item }) {
    return <LetsMeet title={item.value.title} />
}

function HorizontalRuleBlock() {
    return <div className="section-container-y-half"><hr style={{ backgroundColor: 'var(--grey)' }} /></div>
}

// based on Specialties in ServicePage.jsx just with different colors and could be refactored to be more be used in both places
function ImageGridDropdownBlock({ item }) {
    const specialties = item.value

    const [currentlyOpen, setCurrentlyOpen] = useState(null)
    const toggle = index => index === currentlyOpen ? setCurrentlyOpen(null) : setCurrentlyOpen(index)

    return (
        <div className="service-specialties">
            <div className="large-screen-container">
                <div className="three-column-grid">
                    {specialties.map((specialty, index) => {
                        const isOpen = currentlyOpen === index
                        return <div onClick={_ => toggle(index)} className="specialty" key={index}>
                            {specialty?.image?.small_image && <img {...specialty.image.small_image} />}
                            <div className="h3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h2 className="h3">{ specialty.title }&nbsp;</h2>
                                {!isOpen ? (
                                    <button className="unstyled-button" style={{ position: 'relative', top: '.25rem', maxHeight: 35 }} aria-label="Open">
                                        <img src="/static/images/chevron-up.svg" style={{transform: 'rotate(180deg)', filter: 'invert(100%)'}} alt="Down arrow" />
                                    </button>
                                ) : (
                                    <button className="unstyled-button" style={{ position: 'relative', top: '.25rem', maxHeight: 35 }} aria-label="Close">
                                        <img src="/static/images/chevron-up.svg" style={{filter: 'invert(100%)'}} alt="Up arrow" />
                                    </button>
                                )}
                            </div>
                            {isOpen && <p className="h6">{ specialty.text }</p>}
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

function SectionId({ item }) {
    return <div id={item.value.section_id} />
}


export default function StreamFieldBlock(props) {
    const blockTypes = {
        text: Richtext,
        image_block: Image,
        value_block: ValuesListBlock,
        team_members: TeamMembers,
        quote_block: QuoteBlock,
        grid_block: GridBlock,
        steps_block: StepsBlock,
        video_block: VideoBlock,
        lets_meet_block: LetsMeetBock,
        horizontal_rule: HorizontalRuleBlock, // same as image with text dropdown on the service page
        image_grid_dropdown_block: ImageGridDropdownBlock,
        section_id: SectionId,
    }

    const Block = blockTypes[props.item.type]
    return <Block {...props} />
}
