import React, { useState, useEffect } from 'react'


export const formatDate = first_published_at => {
    if (!first_published_at) {
        return null
    }

    // convert to date from Wagtail into a human readable date as an array
    // then select the items in the array which we want to display
    const dateArray = new Date(first_published_at).toString().split(' ')
    return `${dateArray[2]} ${dateArray[1]}, ${dateArray[3]}`
}

const sortByOrder = (a, b) => {
    if (a.weight < b.weight) {
        return -1
    }
    if (a.weight > b.weight) {
        return 1
    }

    return 0 // not returning 0 will cause bugs in Firefox
}

const sortByDate = (a, b) => {
    if (a.meta.first_published_at < b.meta.first_published_at) {
        return 1
    }
    if (a.meta.first_published_at > b.meta.first_published_at) {
        return -1
    }

    return 0
}

export const orderArticles = articles => {
    if (!articles) return []

    // First order by date, with newest at the start. In theory deep clone shouldn't be necessary but it seems to not work correctly without it.
    const orderByDate = [...articles.sort(sortByDate)]
    // Then order by .weight property with lowest number at the start
    return [...orderByDate.sort(sortByOrder)]
}

// https://usehooks.com/useWindowSize/
export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    })

    useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
        // Set window width/height to state
        setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        })
    }

    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export const eagerlyLazyLoadImages = lazyLoadUrls => {
    return (
        <div className="eager-loading" style={{ visibility: 'hidden', height: 0, width: 0 }}>
            {lazyLoadUrls && lazyLoadUrls.map((url, index) => (
                <img loading="lazy" decoding="async" src={url} alt="" style={{ height: 0, width: 0 }} key={index} />
            ))}
        </div>
    )
}