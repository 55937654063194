import React, { useState, useEffect, useRef } from 'react'

import ReCAPTCHA from 'react-google-recaptcha'

import HeroBanner from '../HeroBanner'


function ContactMethods() {
    return (
        <div className="contact-methods section-container three-column-grid">
            <div>
                <a href="mailto:contact@obvious.agency">
                    <img src="/static/images/illustrations/Email.svg" width="200" height="177" alt="" />
                    <h3 className="h3">Email</h3>
                    <p className="h6">contact@obvious.agency</p>
                </a>
            </div>
            <div>
                <a href="tel:+6427 819 3722">
                    <img src="/static/images/illustrations/Phone.svg" width="200" height="177" alt="" />
                    <h3 className="h3">Phone</h3>
                    <p className="h6">+64 27 819 3722</p>
                </a>
            </div>
            <div>
                <img src="/static/images/illustrations/Social.svg" width="200" height="177" alt="" />
                <h3 className="h3">Social</h3>
                <div className="social-media">
                    <a href="https://www.linkedin.com/company/obviousagency/" target="_blank" rel="noopener" aria-label="Linkedin">
                        <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                    <a href="http://www.instagram.com/obvious.agency" target="_blank" rel="noopener" aria-label="Instagram">
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="http://www.facebook.com/obvious.brand.partners" target="_blank" rel="noopener" aria-label="Facebook">
                        <i className="fa-brands fa-facebook"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}

function ContactForm() {
    const [formResponse, setFormResponse] = useState(null)

    const recaptchaRef = useRef('')
    const onReCaptchaChange = () => {
        const recaptchaValue = recaptchaRef.current.getValue()
        document.getElementById('captcha').value = recaptchaValue
    }

    useEffect(() => {
        // todo: temp hack to get csrf token
        const csrfEl = document.getElementById('csrf-token').innerHTML
        document.getElementById('csrf').innerHTML = csrfEl
    }, [])

    useEffect(() => {
        const formRes = JSON.parse(document.getElementById('form-response').textContent)
        // console.log('formRes', formRes) // note: uncomment to debug
        if (formRes) {
            setFormResponse(formRes)
        }
    }, [])

    return (
        <section className="contact-form section-container-padding">
            <h2 className="h5">Forms more your thing? Fill this out and we'll be in touch.</h2>
            <br />

            <form action="/contact/" method="post">
                <div className="form-wrapper">
                    <div>
                        <label htmlFor="name">Full Name: </label>
                        <input id="name" type="text" name="name" required />
                    </div>

                    <div>
                        <label htmlFor="company">Company / Organisation: </label>
                        <input id="company" type="text" name="company" required />
                    </div>

                    <div>
                        <label htmlFor="email">Email: </label>
                        <input id="email" type="email" name="email" required />
                    </div>

                    <div>
                        <label htmlFor="phone">Phone: </label>
                        <input id="phone" type="text" name="phone" required />
                    </div>

                    <div className="textarea-wrapper">
                        <label htmlFor="message">Your Message: </label>
                        <textarea id="message" name="message" rows="4" cols="50" required />
                    </div>

                    <div style={{ marginBottom: '.75rem' }}>
                        <input id="captcha" type="hidden" name="captcha" />
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LeJro0jAAAAAJh8Aa8QXlNDiflRCKFvCbwtwzrS"
                            onChange={onReCaptchaChange}
                        />
                    </div>
                </div>

                <div id="csrf"></div>

                {formResponse && <div style={{ margin: '1.5rem 0 .75rem' }}>
                    {Object.entries(formResponse._errors).map((error, index) => (
                        <div style={{ color: 'red' }} key={index}>
                            {error[0]}: {error[1].join(' ')}
                        </div>
                    ))}
                </div>}

                <input type="submit" value="Send" className="button primary" />
            </form>
        </section>
    )
}


export default function ContactPage() {
    return (
        <div>
            <HeroBanner
                title="Let's Join Forces"
                subtitle="We call ourselves Brand Partners because we believe in the power of collaboration. So let's join forces and do something awesome!"
            />

            <div className="large-screen-container">
                <ContactMethods />
            </div>

            <div style={{ background: 'var(--light-grey)'}}>
                <div className="large-screen-container">
                    <ContactForm />

                    <div className="visit-us section-container-padding two-column-grid">
                        <div className="flex-center-vertically">
                            <div>
                                <h3 className="h5">Visit us</h3>
                                <address>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Obvious+Brand+Partners+%7C+Design+%26+Digital+Marketing+Agency/@-41.2914101,174.7714342,17z/data=!3m1!4b1!4m5!3m4!1s0x6d38afcf5f6d6ebd:0xe7968b7c82a52197!8m2!3d-41.2914142!4d174.7736229" style={{ display: 'block' }}>
                                        Level 4, 187 Willis Street <br/>
                                        Te Aro <br/>
                                        Wellington, 6011 <br/>
                                        New Zealand
                                    </a>
                                </address>
                            </div>
                        </div>
                        <div>
                            <img src="/static/images/contact_page/contact-page-team.png" alt="The team at Obvious Brand Partners" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}