import React from 'react'

import apiV1 from '../../api'
import Spinner from '../../components/Spinner'
import StreamFieldBlock from '../StreamFieldBlock'
import MoreArticles, { caseStudiesMoreArticlesConfig } from '../MoreArticles'
import ErrorMessage from '../ErrorMessage'


export default function CaseStudyPage({ slug }) {
    const articles = apiV1.useApi(slug, {
        type: 'base.CaseStudyPage',
        slug: slug,
        fields: 'title,title_color,tags,what_we_did,summary,content,banner_image_original,content',
        limit: 1,
    })

    const moreArticlesData = apiV1.useApi(null, {
        type: 'base.CaseStudyPage',
        fields: 'first_published_at,title,summary,summary_image_small',
        limit: 3,
        order: 'weight',
        hidden: false
    })

    if (articles === null) {
        return <Spinner />
    } else if (articles === false) {
        return <ErrorMessage />
    } else if (!articles.length) {
        return 'Not found' // todo: remove as this is not handled in ViewWrapper
    }

    const article = articles[0]
    const moreArticles = moreArticlesData && moreArticlesData.filter(a => a.id !== article.id).slice(0,2)

    return (
        <div>
            <article className="large-screen-container">
                <div className="article-banner section-container">
                    {article.banner_image_original && <img fetchpriority="high" {...article.banner_image_original} src={article.banner_image_original.url} />}
                    <h1 className="h2" style={{ margin: '2rem 0 1rem', color: article.title_color }}>{ article.title }</h1>
                    <h2 className="h5">{ article.summary }</h2>

                    {!!article.what_we_did?.length && (
                        <div className="tag-list flex-wrap" style={{ margin: '1rem 0' }}>
                            {article.what_we_did.map(tag => <div key={tag}>
                                <h3 className="h6">{tag}</h3>
                                <i className="fa-solid fa-period"></i>
                            </div>)}
                        </div>
                    )}
                </div>

                <div className="section-container">
                    {article.content.map((item, index) => <StreamFieldBlock item={item} key={item.id} />)}
                </div>
            </article>

            <MoreArticles {...caseStudiesMoreArticlesConfig} articles={moreArticles} mainHeading="Check out more of our case studies" className="xxl:section-container-x-padding" />
        </div>
    )
}