import React from 'react'
import { Link } from 'wouter'

import apiV1 from '../../api'
import { formatDate, orderArticles } from '../../utils'
import Spinner from '../../components/Spinner'
import HeroBanner from '../HeroBanner'
import ErrorMessage from '../ErrorMessage'


function ArticleList({ articles, slugPrefix }) {
    if (!articles.length) {
        return <p className="h6 section-container" style={{ color: 'white' }}>No articles found.</p>
    }

    return (
        <div className="article-list blog-list section-container">
            {orderArticles(articles).map((article, index) => (
                <Link to={`${slugPrefix}${article.meta.slug}/`} key={article.title}>
                    <a className="article-link" aria-label={article.title}>
                        <div className="article-image fit-image">
                            <img loading={index < 3 ? "eager" : "lazy"} {...article.summary_image_small} src={article.summary_image_small?.url} />
                        </div>
                        <div className="article-text" style={{ marginTop: '.25rem' }}>
                            <h2 className="h6">{ article.title }</h2>
                            <p className="date h7" style={{ fontSize: 14, margin: '.5em 0' }}>{ formatDate(article.meta.first_published_at) }</p>
                        </div>
                        <div className="read-more-button button primary">Read more</div>
                    </a>
                </Link>
            ))}
        </div>
    )
}

export default function BlogListPage() {
    const slugPrefix = '/blog/'
    const articles = apiV1.useApi(slugPrefix, {
        type: 'base.BlogPage',
        fields: 'first_published_at,title,summary,summary_image_small',
        limit: 50,
        hidden: false
    })

    return (
        <div>
            <ErrorMessage showIf={articles === false} />

            <HeroBanner title="The Obvious Blog" subtitle="In the spirit of knowledge-sharing, we regularly write about the brand, marketing and digital insights we gain through our own work. Have a read!" />

            <div className="large-screen-container">
                {articles ? <ArticleList slugPrefix={slugPrefix} articles={articles} /> : <Spinner />}
            </div>
        </div>
    )
}
