import React from 'react'
import { Link } from 'wouter'


export default function LetsMeet({ title, buttonText, buttonURL }) {
    return (
        <div className="lets-meet-section">
            <section className="section-container-padding large-screen-container">
                <h2 className="h1" style={{ maxWidth: 1200, margin: '0 auto', marginBottom: 16 }}>{ title }</h2>
                <div className="lets-meet-button">
                    <Link to={buttonURL ? `${buttonURL}/` : '/contact/'}>
                        <a className="button large">{buttonText || 'Get in touch'}</a>
                    </Link>
                </div>
            </section>
        </div>
    )
}