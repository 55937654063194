import React from 'react'
import { Link } from 'wouter'


function QuoteWithImage({ quote, buttons, link_url }) {
    const { text, name, title, image, url } = quote
    const Buttons = buttons || null

    return (
        <div className="quote-section quote-with-image section-container-y-padding">
            <div className="grid-item quote">
                <div className="article-width">
                    <q dangerouslySetInnerHTML={{ __html: text }}></q>
                </div>
                {url && (
                    <div className="tablet-plus-only" style={{ marginTop: '1.5rem' }}>
                        {/* marginTop 1.5rem is a quick fix for large text cropping on tablet, although it may be good to have there in general for extra space */}
                        <Link to={`${url}/`}>
                            <a className="view-case-study-button button black large">View case study</a>
                        </Link>
                        <Buttons />
                    </div>
                )}
            </div>
            <div className="grid-item image">
                {image && <img {...image} />}
                <p className="cite">
                    {link_url ? <a href={link_url}><b>{ name }</b></a> : <b>{ name }</b>}
                    <br />
                    { title }
                </p>
                {url && (
                    <div className="mobile-only" style={{ marginTop: '1.5rem' }}>
                        <Link to={`${url}/`}>
                            <a className="view-case-study-button button black large">View case study</a>
                        </Link>
                        <div style={{ marginTop: '2rem' }}>
                            <Buttons />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

function QuoteWithoutImage({ quote, link_url }) {
    const { text, name, title } = quote

    return <div className="quote-section quote-without-image section-container-y-padding">
        <div>
            <q dangerouslySetInnerHTML={{ __html: text }}></q>
        </div>

        <hr style={{ background: 'var(--green-lighter)' }} />

        <p className="cite">
            {link_url ? <a href={link_url}><b>{ name }</b></a> : <b>{ name }</b>}
            <br />
            { title }
        </p>
    </div>
}

export default function QuoteSection({ quote, buttons }) {
    const link_url =  quote.link?.link_url
    return quote.image ? <QuoteWithImage quote={quote} buttons={buttons} link_url={link_url} /> : <QuoteWithoutImage quote={quote} link_url={link_url} />
}