import React from 'react'

import apiV1 from '../../api'
import { formatDate } from '../../utils'
import Spinner from '../../components/Spinner'
import StreamFieldBlock from '../StreamFieldBlock'
import MoreArticles, { blogMoreArticlesConfig } from '../MoreArticles'
import ErrorMessage from '../ErrorMessage'


export default function BlogPage({ slug }) {
    const articles = apiV1.useApi(slug, {
        type: 'base.BlogPage',
        slug: slug,
        fields: 'title,first_published_at,summary,author,content,banner_image_original,content',
        limit: 1,
    })

    const moreArticlesData = apiV1.useApi(null, {
        type: 'base.BlogPage',
        fields: 'first_published_at,title,summary,summary_image_small',
        order: '-first_published_at',
        hidden: false
    })

    if (articles === null) {
        return <Spinner />
    } else if (articles === false) {
        return <ErrorMessage />
    } else if (!articles.length) {
        return 'Not found' // todo: load 404 component
    }

    const article = articles[0]

    const moreArticles = moreArticlesData && moreArticlesData.filter(a => a.id !== article.id).slice(0,4)

    return (
        <div style={{position: 'relative'}}>
            <article>
                <div className="article-banner">
                    <div className="large-screen-container section-container-padding extra-padding-desktop" style={{ paddingBottom: 0}}>
                        <h1 className="h5">{ article.title }</h1>
                        <div style={{ margin: '1.5rem 0 2.75rem' }}>
                            <p className="h7" style={{ fontSize: 14, marginBottom: '.25rem' }}>{ formatDate(article.meta.first_published_at) }</p>
                            {article.author && <p className="h7" style={{ fontSize: 14 }}>{article.author}</p>}
                        </div>
                        {article.banner_image_original && <img fetchpriority="high" {...article.banner_image_original} src={article.banner_image_original.url} />}
                    </div>
                </div>

                <div className="large-screen-container section-container-padding extra-padding-desktop">
                    {article.content.map((item, index) => <StreamFieldBlock item={item} key={index} />)}
                </div>
            </article>

            <div>
                <MoreArticles {...blogMoreArticlesConfig} articles={moreArticles} className="more-articles-padding-desktop" />
            </div>
        </div>
    )
}