// todo: eventually this can be replaced with the CMS but it's a low priority
export default quotes = [
    {
        text: `It's been an absolute pleasure to work with a team who understands what we do and why we do it and bring our brand to life. They have really helped us create a memorable brand with an amazing online presence. One of the best agencies I have worked with and highly recommend. Obvious makes things obvious!`,
        name: `Diana Sharma`,
        title: `Co-Founder at Mission Ready HQ`,
        image: {
            src: "/static/images/quotes/Diana-Sharma.jpeg",
            height: 500,
            width: 500,
            alt: '',
        },
        url: '/our-work/mission-ready'
    },
    {
        text: `Our team is really happy with how the rebrand has turned out - one of our team members, with 25 years experience in marketing and sales, said that the brand guideline document was one of the best he'd ever seen! Very concise and clearly communicates to the whole team how we should and shouldn't use the brand, while also being practical and giving a variety of options.`,
        name: `Dr. Andrew Chen`,
        title: `Karihi Venture Partner`,
        image: {
            src: "/static/images/quotes/Andrew-Chen.jpeg",
            height: 500,
            width: 500,
            alt: '',
        },
        url: '/our-work/matu'
    },
    {
        text: `The team at Obvious took the time to properly understand me, my goals, and what I was hoping to achieve with my business rebrand. The whole process was straight-forward and the end result is absolutely awesome.`,
        name: `Sheridan Jamieson`,
        title: `Director at Nurture`,
        image: {
            src: "/static/images/quotes/Sheridan-Jamieson.jpeg",
            height: 500,
            width: 500,
            alt: '',
        },
        url: '/our-work/nurture',
    },
]