import React from 'react'


export default function Spinner() {
    return (
        <div className="bg-light spinner-bg">
        <div className="spinner-wrapper">
            <i className="spinner fa-sharp fa-solid fa-spinner-third"></i>
        </div>
        </div>
    )
}
