import React from 'react'

import apiV1 from '../../api'
import Spinner from '../../components/Spinner'
import HeroBanner from '../HeroBanner'
import StreamFieldBlock from '../StreamFieldBlock'


export default function StandardPage({ slug }) {
    const pages = apiV1.useApi(slug, {
        type: 'base.StandardPage',
        slug: slug,
        fields: '*',
        limit: 1,
    })

    const page = pages && pages[0]

    return (
        <div className="large-screen-container">
            <HeroBanner
                title={page?.title}
                subtitle={page?.subtitle || undefined}
            />

            {page ? (
                <div className="section-container">
                    {page.content.map((item, index) => <StreamFieldBlock item={item} key={index} />)}
                </div>
            ) : (
                <Spinner />
            )}
        </div>
    )
}