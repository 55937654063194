import React from 'react'


export default function Steps({ title, step1, step2, step3 }) {
    return (
        <section className="our-approach section-container-y-padding">
            {title && (
                <strong className="h2">{title}</strong>
            )}

            <div className="process-steps">
                <div className="richtext">
                    <img src="/static/images/1.svg" width="75" height="141" alt="Number 1" style={{ display: 'block', margin: '2rem auto 4rem' }} />
                    {step1}
                </div>

                <div className="richtext">
                    <img src="/static/images/2.svg" width="105" height="144" alt="Number 2" style={{ display: 'block', margin: '2rem auto 4rem' }} />
                    {step2}
                </div>

                <div className="richtext">
                    <img src="/static/images/3.svg" width="105" height="144" alt="Number 3" style={{ display: 'block', margin: '2rem auto 4rem' }} />
                    {step3}
                </div>
            </div>
        </section>
    )
}


// todo: should this be editable via the Steps block?
export function OurApproach({ title }) {
    return <Steps
        title={title && <h2 className="h2 article-width">{ title }</h2>}
        step1={(
            <div>
                <h4>Discovery</h4>
                <p>This is where we unpack your business goals, challenges and opportunities for growth. We audit, explore and empathise to understand how your brand is communicated to your audiences.</p>
            </div>
        )}
        step2={(
            <div>
                <h4>Ideate & Create</h4>
                <p>Let's put pen to paper and let the creativity flow. We workshop and collaborate to set a strategic direction. We explore concepts, develop, test, and refine ideas until we reach a sustainable best-for-business solution.</p>
            </div>
        )}
        step3={(
            <div>
                <h4>Deploy</h4>
                <p>Let's get your new project out into the world! We help you launch the brand and get it in front of the right people. We empower your team to take ownership of the new brand and equip them with tools to put the brand to use.</p>
            </div>
        )}
    />
}