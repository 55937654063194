import React, { useState } from 'react'

import apiV1 from '../../api'
import HeroBanner from '../HeroBanner'
import Spinner from '../Spinner'
import StreamFieldBlock from '../StreamFieldBlock'
import MoreArticles, { caseStudiesMoreArticlesConfig } from '../MoreArticles'
import { orderArticles } from '../../utils'


function Specialties({ specialties }) {
    const [currentlyOpen, setCurrentlyOpen] = useState(null)
    const toggle = index => index === currentlyOpen ? setCurrentlyOpen(null) : setCurrentlyOpen(index)

    return (
        <div className="service-specialties">
            <div className="large-screen-container">
                <div className="section-container-padding three-column-grid">
                    {specialties.map((specialty, index) => {
                        const isOpen = currentlyOpen === index
                        return <div onClick={_ => toggle(index)} className="specialty" key={index}>
                            {specialty?.image?.small_image && <img {...specialty.image.small_image} />}
                            <div className="h3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h2 className="h3">{ specialty.title }&nbsp;</h2>
                                {!isOpen ? (
                                    <button className="unstyled-button" style={{ position: 'relative', top: '.25rem', maxHeight: 35 }} aria-label="Open">
                                        <img src="/static/images/chevron-up.svg" style={{transform: 'rotate(180deg)'}} alt="Down arrow" />
                                    </button>
                                ) : (
                                    <button className="unstyled-button" style={{ position: 'relative', top: '.25rem', maxHeight: 35 }} aria-label="Close">
                                        <img src="/static/images/chevron-up.svg" alt="Up arrow" />
                                    </button>
                                )}
                            </div>
                            {isOpen && <p className="h6">{ specialty.text }</p>}
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}


// as this is passed to MoreArticles it receives the first param (slug), then when it's used again it gets the articles to filter
const filterRelevantArticles = slug => article => {
    if (slug.includes('brand') && (article.tags.includes('Brand') || article.tags.includes('Design'))) {
        return true // is brand and design, so filter for Brand and Illustration tag 
    }
    if (slug.includes('web') && article.tags.includes('Website')) {
        return true // is web, so filter for Website tag
    }
    if (slug.includes('marketing') && (article.tags.includes('Marketing') || article.tags.includes('Campaign'))) {
        return true // is marketing and campaign, so filter for Marketing and Campaign tags
    }
}

export default function ServicePage({ slug }) {
    const pageData = apiV1.useApi(slug, {
        type: 'base.ServicePage',
        slug: slug,
        fields: 'subtitle,specialties,content',
    })

    const moreArticlesData = apiV1.useApi(null, {
        type: 'base.CaseStudyPage',
        fields: 'first_published_at,title,summary,summary_image_small,tags',
        limit: 10,
        hidden: false
    })

    const page = pageData && pageData[0]
    const tagFilter = filterRelevantArticles(slug)
    const moreArticles = moreArticlesData && orderArticles(
        moreArticlesData.filter(tagFilter).slice(0,2)
    )

    return (
        <div className="service-page">
            <HeroBanner
                title={page?.title}
                subtitle={page?.subtitle || undefined}
            />

            {page ? (
                page.specialties[0]?.value && <Specialties specialties={page.specialties[0].value} />
            ) : (
                <Spinner />
            )}

            <div className="bg-light" style={{paddingTop: '1rem'}}>
                <div className="large-screen-container">
                    {page && page.content.map((item, index) => (
                        <div className="section-container" key={index}>
                            <StreamFieldBlock item={item} />
                        </div>
                    ))}

                    <MoreArticles {...caseStudiesMoreArticlesConfig} mainHeading="Check out some of our case studies" articles={moreArticles} />
                </div>
            </div>
        </div>
    )
}