import React from 'react'


export default function HeroBanner({ title, subtitle, mobileIllustration, backgroundImages, children }) {
    const BackgroundImages = backgroundImages || null
    const MobileIllustration = mobileIllustration || null

    return (
        <div className="hero-banner" style={{ position: 'relative' }}>
            {backgroundImages && (
                <div className="hero-background">
                    <BackgroundImages />
                </div>
            )}

            <div className="large-screen-container">
                <div className="section-container-padding hero-main flex-center-vertically">
                    <div className="hero-main-content">
                        <h1 className="h1">{ title }</h1>

                        {mobileIllustration && <div className="mobile-only"><MobileIllustration /></div>}

                        {subtitle &&  <h2 className="h5">{ subtitle }</h2>}

                        <div className="banner-children">
                            { children }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}