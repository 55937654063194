import React, { useState } from 'react'

import Spinner from '../../components/Spinner'
import HeroBanner from '../HeroBanner'
import StreamFieldBlock from '../StreamFieldBlock'
import DiscoverySessionCTA from '../DiscoverySessionCTA'
import ErrorMessage from '../ErrorMessage'


function TeamMemberModal({ selectedPerson, setSelectedPersonName }) {
    return (
        <div className="modal is-active" onClick={_ => setSelectedPersonName(null)}>
            <div className="modal-background"></div>

            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-image">
                    {selectedPerson?.image?.small_image_portrait && <img className="fit-image" {...selectedPerson.image.small_image_portrait } />}
                </div>
                <div className="modal-text">
                    <h2 className="h3">{ selectedPerson.name }</h2>
                    <h3 className="h6" style={{ margin: '.25rem 0 .75rem' }}>{ selectedPerson.job_title }</h3>
                    <div className="richtext" dangerouslySetInnerHTML={{__html: selectedPerson.bio}} style={{ paddingBottom: '1rem' }}></div>
                    {selectedPerson.linkedin.custom_url && (
                        <a href={selectedPerson.linkedin.custom_url} target="_blank" rel="noopener" aria-label="LinkedIn">
                            <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                    )}
                </div>
            </div>
            <button onClick={_ => setSelectedPersonName(null)} className="modal-close is-large" aria-label="Close modal"></button>
        </div>
    )
}

const BackgroundImages = () => {
    return <>
        <img src="/static/images/illustrations/brain-cloud-v2.svg" fetchpriority="high" className="section-container" width="443" height="251" style={{ position: 'absolute', top: '-7%', left: 0, }} alt="" />
        <img src="/static/images/illustrations/LightningCloud.svg" fetchpriority="high" className="section-container" width="207" height="167" style={{ position: 'absolute', bottom: '33%', right: '11%', }} alt="" />
    </>
}


export default function AboutPage() {
    const [selectedPersonName, setSelectedPersonName] = useState(null)
    const pageData = apiV1.useApi(null, {
        type: 'base.StandardPage',
        slug: 'about',
        fields: 'content',
        limit: 1,
    })

    const page = pageData?.length === 1 && pageData[0]
    const teamMembers = page?.content && page.content.find(block => block.type === 'team_members')
    const selectedPerson = teamMembers && teamMembers.value.find(person => person.name === selectedPersonName)

    return (
        <div className="about-page">
            <ErrorMessage showIf={pageData === false} />

            <HeroBanner
                title="We're the Obvious Choice"
                subtitle="It takes extraordinary people to produce extraordinary results. And we're anything but normal - that's obvious."
                backgroundImages={BackgroundImages}
            />

            <div className="bg-white">
                <div className="large-screen-container section-container-padding blog-articles">
                    {page?.content ? (
                        page.content.map((item, index) => <StreamFieldBlock item={item} setSelectedPersonName={setSelectedPersonName} key={index} />
                    )) : (
                        <Spinner />
                    )}
                </div>

                <DiscoverySessionCTA>
                    <img src="/static/images/illustrations/brain-cloud-v1.svg" width="443" height="251" />
                </DiscoverySessionCTA>

                {selectedPersonName && <TeamMemberModal selectedPerson={selectedPerson} setSelectedPersonName={setSelectedPersonName} />}
            </div>
        </div>
    )
}